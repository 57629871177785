import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { assetArrayToObjectContentful } from "../lib/graphql-helpers";
import RisingWords from "../components/RisingWords";
import Button from "../components/Button";
import classNames from "classnames";
import "../styles/pages/lineOfBusinessTemplate.scss";
gsap.registerPlugin(ScrollTrigger);

const RenderParagraphs = ({ text }) => {
  const paragraphs = text.split("\n\n");

  return (
    <div className="flex flex-col gap-12 w-full">
      {paragraphs.map((paragraph, index) => {
        const isOneParagraph = paragraphs.length === 1;
        return (
          <p
            className={`ri-anim-left text-darkerGrey ${
              paragraphs.length === 1 ? "text-3xl" : ""
            } ${paragraphs.length === 2 ? "text-2xl" : ""} ${
              paragraphs.length === 3 ? "text-xl" : ""
            } ${paragraphs.length > 3 ? "text-lg" : ""}`}
            key={`paragraph-${index}`}
          >
            {paragraph}
          </p>
        );
      })}
    </div>
  );
};

const LineOfBusinessTemplate = ({ data }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const heroImageRef = useRef(null);
  const secondaryImageRef = useRef(null);
  const lineOfBusinessRef = useRef(null);
  const indexRef = useRef(null);
  const indexRef2 = useRef(null);
  const lineOfBusinessData = data.contentfulLineOfBusinessPage;

  const {
    columns,
    listItems,
    secondaryImage,
    mainImage,
    listItems2,
    columns2,
    listHeader2,
    listHeader,
  } = lineOfBusinessData;
  const animationsRef = useRef([]); // Store animation instances for cleanup

  useEffect(() => {
    if (indexRef.current) {
      const indexRefChildren = [...indexRef.current.children];


      indexRefChildren.forEach((child, index) => {
        // Create GSAP animation and store it for later cleanup
        const anim = gsap.fromTo(
          child,
          {
            y: 100,
            opacity: 0.1,
          },
          {
            y: 0,
            opacity: 1,
            duration: 0.5,
            delay: index * 0.1, // stagger delay between children
            scrollTrigger: {
              trigger: ".first-index-class", // Use the containerRef as trigger
              start: "top bottom", // Start when the top of container hits the bottom of the viewport
              end: "bottom top", // End when bottom of container hits the top of the viewport
              toggleActions: "play none none reset", // Play the animation and reset when scrolling back above
            },
          }
        );

        animationsRef.current.push(anim); // Store animation in the ref
      });
    }
    if (indexRef2.current) {
      const indexRefChildren = [...indexRef2.current.children];

      indexRefChildren.forEach((child, index) => {
        // Create GSAP animation and store it for later cleanup
        const anim = gsap.fromTo(
          child,
          {
            y: 100,
            opacity: 0.1,
          },
          {
            y: 0,
            opacity: 1,
            duration: 0.5,
            delay: index * 0.1, // stagger delay between children
            scrollTrigger: {
              trigger: ".second-index-class", // Use the containerRef as trigger
              start: "top bottom", // Start when the top of container hits the bottom of the viewport
              end: "bottom top", // End when bottom of container hits the top of the viewport
              toggleActions: "play none none reset", // Play the animation and reset when scrolling back above
            },
          }
        );

        animationsRef.current.push(anim); // Store animation in the ref
      });
    }

    // Helper function to create and store animations for later cleanup
    const createAnimation = (animation) => {
      animationsRef.current.push(animation);
    };

    if (lineOfBusinessRef.current) {
      const leftTextElements = Array.from(
        lineOfBusinessRef.current.querySelectorAll(".ri-anim-left")
      );
      const rightTextElements = Array.from(
        lineOfBusinessRef.current.querySelectorAll(".ri-anim-right")
      );
      const bottomTextElements = Array.from(
        lineOfBusinessRef.current.querySelectorAll(".ri-anim-bottom")
      );

      // Animate left, right, and bottom elements with proper ScrollTrigger
      leftTextElements.forEach((ele) => {
        createAnimation(
          gsap.fromTo(
            ele,
            { x: -150, opacity: 0 },
            {
              x: 0,
              opacity: 1,
              duration: 1,
              ease: "power1.out",
              scrollTrigger: {
                trigger: ele,
                start: "top bottom",
                end: "bottom top",
              },
            }
          )
        );
      });

      rightTextElements.forEach((ele) => {
        createAnimation(
          gsap.fromTo(
            ele,
            { x: 150, opacity: 0 },
            {
              x: 0,
              opacity: 1,
              duration: 0.75,
              ease: "power1.out",
              scrollTrigger: {
                trigger: ele,
                start: "top bottom",
                end: "bottom top",
              },
            }
          )
        );
      });

      bottomTextElements.forEach((ele) => {
        createAnimation(
          gsap.fromTo(
            ele,
            { y: 150, opacity: 0 },
            {
              y: 0,
              opacity: 1,
              duration: 0.75,
              ease: "power1.out",
              scrollTrigger: {
                trigger: ele,
                start: "top bottom",
                end: "bottom top",
              },
            }
          )
        );
      });

      // Animate rising text elements
      const risingTextAnimElements = Array.from(
        lineOfBusinessRef.current.querySelectorAll(".rising-text-anim")
      );

      risingTextAnimElements.forEach((text) => {
        const subHeaderWords = Array.from(text.children);
        const wordElements = subHeaderWords.map((word) => word.children[0]);

        gsap.set(wordElements, { y: 40 });

        wordElements.forEach((wordElement, index) => {
          createAnimation(
            gsap.to(wordElement, {
              y: 0,
              duration: 1,
              delay: index * 0.03,
              scrollTrigger: {
                trigger: wordElement,
                start: "top bottom",
                end: "bottom top",
                toggleActions: "play none none reset",
              },
            })
          );
        });
      });
    }

    // Animate hero image
    if (heroImageRef.current) {
      createAnimation(
        gsap.fromTo(
          heroImageRef.current,
          { y: -400 },
          {
            y: 0,
            scrollTrigger: {
              trigger: heroImageRef.current,
              scrub: true,
              start: "top bottom",
              end: "bottom top-=400",
            },
          }
        )
      );
    }

    // Animate secondary image
    if (secondaryImageRef.current) {
      createAnimation(
        gsap.fromTo(
          secondaryImageRef.current,
          { y: -400 },
          {
            y: 0,
            scrollTrigger: {
              trigger: secondaryImageRef.current,
              scrub: true,
              start: "top bottom",
              end: "bottom top-=400",
            },
          }
        )
      );
    }

    // Cleanup function to kill animations and scroll triggers
    return () => {
      animationsRef.current.forEach((anim) => {
        anim.scrollTrigger?.kill(); // Properly kill the associated ScrollTrigger
        anim.kill(); // Kill the GSAP animation itself
      });
      animationsRef.current = []; // Clear the reference array
    };
  }, []);

  const refreshScrollTrigger = () => {
    ScrollTrigger.refresh();
  };

  return (
    <div
      ref={lineOfBusinessRef}
      className="flex flex-col items-center justify-center w-full"
    >
      <section className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-col items-center justify-center w-full max-w-screen-2xl box-border px-8 py-8 sm:py-12  md:py-24">
          <div className="flex flex-col w-full xl:h-[90vh] items-center justify-between">
            <div className="w-full flex flex-col items-start sm:pt-16 gap-4">
              {lineOfBusinessData.name && <RisingWords
                className={
                  "text-black sm:text-[100px] lg:text-[120px] gap-4 md:gap-6 lg:gap-12 xl:text-8xl lg:text-6xl md:text-5xl sm:text-4xl text-3xl font-normal"
                }
                words={lineOfBusinessData.name}
              />}
              <div className="w-full flex items-start md:pb-16 lg:pb-32">
                <div className="max-w-[400px] w-full">
                  <Button
                    text="text-[32px!important]"
                    theme="hollow-large-black"
                    to="/contact"
                    className="items-start justify-start"
                  >
                    Get in touch
                  </Button>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row items-center justify-between py-16">
              <div className="xl:max-w-[40vw]">
                {lineOfBusinessData.header && <RisingWords
                  className={"text-black text-2xl sm:text-4xl max-w-2xl gap-1"}
                  words={lineOfBusinessData.header}
                />}
              </div>
              <div className="flex gap-2 items-center mt-6">
                <h5 className="text-lg text-black">Learn more</h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="var(--black)"
                    d="M11 4h2v12l5.5-5.5l1.42 1.42L12 19.84l-7.92-7.92L5.5 10.5L11 16z"
                  />
                </svg>
              </div>
            </div>
          </div>
          {mainImage && (
            <div className="flex items-start justify-start box-border w-full overflow-hidden rounded-[24px] h-[30vh] sm:h-[50vh] md:h-[55vh] lg:h-[70vh] xl:h-[80vh] relative rounded-custom main-image-class">
              <div
                ref={heroImageRef}
                className="flex flex-col items-start justify-start w-full rounded-custom overflow-hidden"
              >
                <GatsbyImage
                  image={mainImage.gatsbyImageData}
                  alt={lineOfBusinessData.header || "Main Image"}
                />
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-col lg:flex-row justify-between w-full max-w-screen-2xl box-border px-8 py-8 sm:py-12 md:py-24 gap-12">
          <div className="w-full flex flex-col justify-between gap-16">
            {lineOfBusinessData.quote && <RisingWords
              className={"text-black text-4xl md:text-6xl max-w-2xl"}
              words={lineOfBusinessData.quote}
            />}
            <RenderParagraphs
              text={lineOfBusinessData.paragraphOne.paragraphOne}
            />
          </div>
          {secondaryImage && (
            <div className="flex items-center lg:items-start justify-center lg:justify-start box-border w-full overflow-hidden rounded-[24px] h-[50vh] sm:h-[80vh] relative rounded-custom">
              <div
                ref={secondaryImageRef}
                className="flex flex-col items-center lg:items-start justify-center lg:justify-start w-full rounded-custom overflow-hidden"
              >
                <GatsbyImage
                  image={secondaryImage.gatsbyImageData}
                  alt={lineOfBusinessData.header || "Secondary Image"}
                  className="rounded-[24px] w-full"
                />
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-col items-center justify-center w-full max-w-screen-2xl box-border px-8 py-8 sm:py-12 md:py-24 gap-16">
          {lineOfBusinessData.listHeader && <RisingWords
            className={
              "text-black text-4xl md:text-6xl max-w-4xl items-center justify-center"
            }
            words={listHeader}
          />}
          {listItems && (
            <ul
              ref={indexRef}
              className={`grid ${
                columns === 3 ? "grid-cols-1 sm:grid-cols-3 gap-4" : ""
              } ${columns === 4 ? "grid-cols-1 sm:grid-cols-4 gap-4" : ""} ${
                columns === 2 ? "grid-cols-1 sm:grid-cols-2 gap-8" : ""
              } first-index-class`}
            >
              {listItems.map((item, index) => {
                const { title, description, gatsbyImageData } = item;

                return (
                  <li
                    className={`flex flex-col justify-between ${
                      columns === 4 ? "gap-2" : "gap-4"
                    }`}
                    key={index}
                  >
                    <RisingWords
                      className={`text-darkerGrey ${
                        columns === 4 ? "text-2xl gap-0" : ""
                      } ${columns === 3 ? "text-3xl" : "l"} ${
                        columns === 2 ? "text-4xl" : "l"
                      }`}
                      words={title}
                    />
                    <div
                      className={`transition-all duration-300 ease-in-out gap-3 flex flex-col`}
                    >
                      <div
                        className={`${
                          columns === 4 ? "max-w-[356px]" : "max-w-[540px]"
                        }] w-full rounded-lg overflow-hidden shadow-custom-light`}
                      >
                        <GatsbyImage
                          className="hover:scale-105 transition-all duration-300 ease-in-out brightness-90 hover:brightness-100"
                          key={index}
                          image={gatsbyImageData}
                          alt={title || `List Image ${index + 1}`}
                        />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </section>
      {listItems2 && (
        <section className="flex flex-col items-center justify-center w-full">
          <div className="flex flex-col items-center justify-center w-full max-w-screen-2xl box-border px-8 py-8 sm:py-12 md:py-24 gap-16">
            <RisingWords
              className={
                "text-black text-4xl md:text-6xl max-w-4xl items-center justify-center"
              }
              words={listHeader2}
            />
            {listItems2 && (
              <ul
                ref={indexRef2}
                className={`grid ${
                  columns2 === 3 ? "grid-cols-1 sm:grid-cols-3 gap-4" : ""
                } ${columns2 === 4 ? "grid-cols-1 sm:grid-cols-4 gap-4" : ""} ${
                  columns2 === 2 ? "grid-cols-1 sm:grid-cols-2 gap-8" : ""
                } second-index-class`}
              >
                {listItems2.map((item, index) => {
                  const { title, description, gatsbyImageData } = item;

                  return (
                    <li
                      className={`flex flex-col justify-between ${
                        columns2 === 4 ? "gap-2" : "gap-4"
                      }`}
                      key={index}
                    >
                      <RisingWords
                        className={`text-darkerGrey ${
                          columns2 === 4 ? "text-2xl gap-0" : ""
                        } ${columns2 === 3 ? "text-3xl" : "l"} ${
                          columns2 === 2 ? "text-4xl" : "l"
                        }`}
                        words={title}
                      />
                      <div
                        className={`transition-all duration-300 ease-in-out gap-3 flex flex-col`}
                      >
                        <div
                          className={`${
                            columns2 === 4 ? "max-w-[356px]" : "max-w-[540px]"
                          }] w-full rounded-lg overflow-hidden shadow-custom-light`}
                        >
                          <GatsbyImage
                            className="hover:scale-105 transition-all duration-300 ease-in-out brightness-90 hover:brightness-100"
                            key={index}
                            image={gatsbyImageData}
                            alt={title || `List Image ${index + 1}`}
                          />
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </section>
      )}
      <section className="w-full flex flex-col items-center justify-center max-w-screen-2xl protect-image-class rounded-md bg-white">
        <div className="w-full bg-white box-border flex flex-col items-center justify-center max-w-screen-2xl py-16 px-8 box-border gap-8 px-8">
          <div className="w-full flex-col sm:px-8 sm:py-16 rounded-[48px] flex items-center justify-center gap-12">
            <RisingWords
              words="Want to learn more?"
              className="text-black xl:text-8xl lg:text-6xl md:text-2xl sm:text-xl text-4xl"
            />
            <div className="w-full flex flex-col md:flex-row items-center justify-center gap-4">
              <div className="w-full max-w-[300px]">
                <Button theme="hollow-large-black" to="/contact">
                  Reach out to us
                </Button>
              </div>
              <div className="w-full max-w-[300px]">
                <Button theme="large-blue" to="/industries">
                  Other services
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LineOfBusinessTemplate;

export const query = graphql`
  query ($id: String!) {
    contentfulLineOfBusinessPage(contentful_id: { eq: $id }) {
      id
      isLive
      header
      name
      quote
      listHeader
      listHeader2
      columns
      columns2
      paragraphOne {
        paragraphOne
      }
      mainImage {
        gatsbyImageData(width: 1600, placeholder: BLURRED)
        fluid {
          src
          srcSet
          sizes
        }
      }
      secondaryImage {
        gatsbyImageData(width: 800, placeholder: BLURRED)
        fluid {
          src
          srcSet
          sizes
        }
      }
      listItems {
        gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 70, width: 540)
        fluid {
          src
          srcSet
          sizes
        }
        title
        description
      }
      listItems2 {
        gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 70, width: 540)
        fluid {
          src
          srcSet
          sizes
        }
        title
        description
      }
    }
  }
`;
